.ProfileMenuWrapper {
  position: relative;
}

.ProfileMenuContainer {
  position: absolute;
  top: 20px;
  right: 0px;
  width: 240px;
  background: var(--color-white);
  box-shadow: 0px 0px var(--box-shadow-spread) var(--color-box-shadow);
  padding: var(--spacing-s) 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
