.Navigation {
  width: 104px;
  flex: 0 0 104px;
  flex-direction: column;
  min-height: 100vh;
}

.Navigation .NavigationLogo {
  width: 60px;
  margin: var(--spacing-ll) var(--spacing-ml);
}

.Navigation .NavigationElement {
  height: 104px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Navigation .NavigationElement svg {
  width: 24px;
  height: 24px;
}

.DeveloperNavigation {
  background-color: var(--color-grey);
}

.DeveloperNavigation .NavigationElement:hover {
  background-color: var(--color-grey-80);
}

.DeveloperNavigation .NavigationElement_active {
  background-color: var(--color-grey-80);
}
