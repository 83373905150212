.HorizontalStatusContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.HorizontalStatusContainer > p {
  color: var(--color-white);
}

.HorizontalStatusContainer .HorizontalStatusBar {
  display: flex;
  flex-direction: row;
  height: 8px;
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-s);
}

.HorizontalStatusContainer .HorizontalStatusBar .HorizontalStatusBarFilled {
  background-color: var(--color-green);
}

.HorizontalStatusContainer .HorizontalStatusBar .HorizontalStatusBarUnfilled {
  background-color: var(--color-green-20);
}
