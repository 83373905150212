.ReadonlyText {
  width: 100%;
}

.ReadonlyTextLabel {
  display: block;
  margin-bottom: var(--spacing-m);
}

.ReadonlyNoDataAvailable {
  color: var(--color-grey-40);
}

.ReadonlyText .link {
  font-weight: 700;
  color: var(--color-blue);
}
