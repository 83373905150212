.Toggle {
  display: inline-block;
}

.Toggle > label {
  cursor: pointer;
  user-select: none;
  font-weight: 400;
}

.Toggle .ToggleComponent {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0;
  vertical-align: middle;

  /*Kana styles*/
  width: 40px;
  height: 24px;
  border: none;
  border-radius: 100px;
  background-color: var(--color-blue-40);
}

.Toggle:hover .ToggleComponent:not(.ToggleComponent-checked) {
  /*Kana styles*/
  background-color: var(--color-blue-20);
  border: none;
}

.Toggle .ToggleComponent:after {
  position: absolute;
  border-radius: 50% 50%;
  content: " ";

  /*Kana styles*/
  box-shadow: none;
  width: 16px;
  height: 16px;
  left: 4px;
  top: 4px;
  background-color: var(--color-white);
}

.Toggle .ToggleComponent:focus-visible {
  /*Kana styles*/
  outline: 1px dashed var(--color-grey-40);
  outline-offset: var(--spacing-xs);
}

.Toggle .ToggleComponent-checked {
  /*Kana styles*/
  background-color: var(--color-blue);
  border: none;
}

.Toggle:hover .ToggleComponent-checked {
  /*Kana styles*/
  background-color: var(--color-blue-80);
  border: none;
}

.Toggle .ToggleComponent-checked:after {
  /*Kana styles*/
  left: 20px;
  width: 16px;
  height: 16px;
}

/*Kana styles*/
.Toggle.Toggle_leftLabel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Toggle.Toggle_leftLabel > label {
  padding-right: var(--spacing-s);
}

.Toggle.Toggle_rightLabel {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}

.Toggle.Toggle_rightLabel > label {
  padding-left: var(--spacing-s);
}
