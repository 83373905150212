.ProjectTypesSection .ProjectTypesLinks {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-sl);
  margin-top: var(--spacing-ml);
}

.ProjectTypesSection .ProjectTypesLinks .body1 > a {
  color: var(--color-blue);
  text-decoration: underline;
}
