.DeveloperHeader {
  display: flex;
  flex-direction: row;
  margin-top: var(--spacing-ll);
  align-items: center;
  justify-content: space-between;
}

.DeveloperHeader > button {
  line-height: var(--line-height-body1);
  padding-left: 0;
}

.DeveloperHeader * svg {
  width: 16px;
  height: 16px;
  padding-bottom: var(--spacing-xs);
}
