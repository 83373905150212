.VersionBannerWrapper {
  position: fixed;
  width: 100%;
  top: 0;
  height: 100px;
  z-index: 1;
}

.VersionBannerWrapper .VersionBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-ml) var(--spacing-ll) var(--spacing-ml) var(--spacing-l);
  background-color: var(--color-grey);
  filter: opacity(98%);

  width: inherit;
}

.VersionBannerWrapper .VersionBanner .TextArea {
  height: 48px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: center;
  row-gap: var(--spacing-s);
  color: var(--color-white);
}

.VersionBannerWrapper .VersionBanner .TextArea > h3,
.VersionBannerWrapper .VersionBanner .FormArea > h3 {
  color: var(--color-white);
  font-size: var(--font-size-body1);
}

.VersionBannerWrapper .VersionBanner .FormArea {
  display: flex;
  align-items: center;
}

.VersionBannerWrapper .VersionBanner .FormArea .Select {
  position: relative;
  top: var(--spacing-xs);
  width: 210px;
  margin-left: var(--spacing-m);
}

.VersionBannerWrapper .VersionBanner .FormArea .Button {
  height: 48px;
  font-size: var(--font-size-body1);
  margin-left: var(--spacing-l);
}
