.ProjectDetailsBasic_ValidatorContainer .ImageContainer {
  padding-left: var(--spacing-sl);
}

.ProjectDetailsBasic_ValidatorContainer .ImageContainer > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--color-grey-20);
}

.ProjectDetailsBasic_ValidatorContainer a {
  color: var(--color-blue);
}
