.Avatar {
  display: flex;
  align-items: center;
}

.Avatar > span {
  padding-right: var(--spacing-s);
  font-weight: bold;
}

.AvatarImage {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid var(--color-grey-20);
}

.AvatarInitial {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: var(--font-size-body1);
  line-height: var(--line-height-body1);
  font-weight: bold;
}

.AvatarAnchor:hover {
  cursor: pointer;
}
