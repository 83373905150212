.PublicProjectTemplateComponent .PublicProjectHeader_Details {
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PublicProjectTemplateComponent .TemplateTabs {
  background-color: var(--color-light-grey);
}

.PublicProjectTemplateComponent .TemplateTabs.TabsContent {
  padding: 0;
}

.PublicProjectTemplateComponent .TemplateTabs.TabsContent .PublicProjectTabSection {
  background-color: var(--color-white);
  padding: var(--spacing-2xl);
  margin-bottom: var(--spacing-ml);
}

.PublicProjectTemplateComponent .PublicProjectHeader_Details > div {
  display: flex;
  align-items: center;
  gap: var(--spacing-m);
}

.PublicProjectTemplateComponent .PublicProjectHeader_Details > div > span {
  margin-left: var(--spacing-m);
}

.PublicProjectTemplateComponent .PublicProjectHeader_DeveloperDetails {
  display: flex;
  align-items: center;
}

.PublicProjectTemplateComponent .PublicProjectHeader_DeveloperDetails {
  display: flex;
  gap: var(--spacing-ml);
  align-items: center;
}

.PublicProjectTemplateComponent .PublicProjectHeader_DeveloperDetails > a {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
  color: var(--color-grey);
}

.PublicProjectTemplateComponent .PublicProjectHeader > button {
  padding-left: 0;
}
