.RemainingTimeContainer {
  display: flex;
  column-gap: var(--spacing-s);
  align-items: center;
}

.RemainingTimeContainer.High {
  color: var(--color-coral);
}

.RemainingTimeContainer.Low > svg > circle {
  fill: var(--color-green);
}

.RemainingTimeContainer.Medium > svg > circle {
  fill: var(--color-yellow);
}
