.Step {
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: var(--spacing-m);
}

.Step .Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Step .Header:hover {
  cursor: pointer;
}

.Step .Header .HeaderLeftSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: var(--spacing-m);
}

.Step .SubSteps {
  margin-top: var(--spacing-ml);
  margin-bottom: calc(-1 * var(--spacing-s));
}

.Step .SubSteps .SubStep {
  position: relative;
  display: flex;
  column-gap: var(--spacing-l);
  margin-left: var(--spacing-s);
  align-items: center;
  margin-bottom: var(--spacing-ml);
}

.Step .SubSteps .SubStep .VerticalLineLightIcon {
  position: absolute;
  margin-bottom: calc(var(--spacing-ll) - var(--spacing-xs));
  margin-left: var(--spacing-xs);
}

@media only screen and (min-width: 1024px) {
  .Step .SubSteps .SubStep .VerticalLineLightIcon {
    margin-bottom: var(--spacing-ll);
  }
}
