.MultipleFileDownloadWrapper {
  width: 100%;
}

.MultipleFileDownloadWrapper {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-m);
}

.MultipleFileDownloadWrapper .MultipleFileDownload {
  display: flex;
  flex-direction: row;
  column-gap: var(--spacing-s);
  cursor: pointer;
  width: fit-content;
}

.MultipleFileDownloadWrapper .MultipleFileDownload > h3 {
  color: var(--color-blue);
  text-decoration: underline;
}

.MultipleFileDownloadWrapper .MultipleFileDownload:hover > h3,
.MultipleFileDownloadWrapper .MultipleFileDownload:hover > svg > path {
  color: var(--color-blue-60);
  stroke: var(--color-blue-60);
}

.MultipleFileDownloadWrapper .MultipleFileDownload:active > h3,
.MultipleFileDownloadWrapper .MultipleFileDownload:active > svg > path {
  color: var(--color-blue);
  stroke: var(--color-blue);
}
