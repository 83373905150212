.ProjectCard {
  display: flex;
  background-color: var(--color-white);
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
}

.ProjectCard .ProjectCardImageBlock {
  width: 184px;
  height: auto;
  position: relative;
  background-position: center;
  background-size: cover;
}

.ProjectCard .ProjectCardImageBlock > span {
  width: 100%;
  height: 29px;
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: var(--line-height-h2);
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-grey);
  opacity: 0.8;
}

.ProjectCard .ProjectCardContent {
  display: flex;
  flex-direction: row;
  width: calc(100% - 184px);
}

.ProjectCard .ProjectCardDetails {
  width: calc(100% - calc(392px - 184px));
  min-width: 290px;
  padding: var(--spacing-ml) var(--spacing-ml) var(--spacing-m) var(--spacing-ml);
}

.ProjectCard .ProjectCardContent .VerticalDivider {
  visibility: visible;
}

.ProjectCard .ProjectCardContent .HorizontalDivider {
  visibility: hidden;
}

.ProjectCard .ProjectCardDetails .ProjectCardDetailsHeader {
  display: flex;
  align-items: center;
  gap: var(--spacing-m);
  margin-bottom: var(--spacing-m);
}

.ProjectCard .ProjectCardDetails .ProjectCardDetailsDeveloper {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-ml);
}

.ProjectCard .ProjectCardDetails .ProjectCardDetailsDeveloper > img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid var(--color-grey-20);
  margin-right: var(--spacing-s);
}

.ProjectCard .ProjectCardDetailsDeveloper > span {
  font-weight: 700;
  margin-right: var(--spacing-m);
}

.ProjectCard .ProjectCardDetails .ProjectCardDetailsTags {
  margin-top: var(--spacing-ml);
}

.ProjectCard .ProjectCardDetails .ProjectCardDetailsTags > div {
  margin-right: var(--spacing-m);
}

.ProjectCard .ProjectCardKeyDataContainer {
  min-width: 248px;
  padding: var(--spacing-m);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-m);
}

@media only screen and (max-width: 1224px) {
  .ProjectCard .ProjectCardDetails .ProjectCardDetailsHeader {
    flex-wrap: wrap;
  }

  .ProjectCard .ProjectCardDetails .ProjectCardDetailsDeveloper {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 768px) {
  .ProjectCard .ProjectCardContent {
    display: flex;
    flex-direction: column;
  }

  .ProjectCard .ProjectCardContent .VerticalDivider {
    visibility: hidden;
  }

  .ProjectCard .ProjectCardContent .HorizontalDivider {
    visibility: visible;
  }

  .ProjectCard .ProjectCardDetails {
    width: 504px;
  }

  .ProjectCard .ProjectCardKeyDataContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: var(--spacing-m) 0 var(--spacing-m) 0;
    gap: var(--spacing-m);
  }
}
