.TooltipSlider.rc-tooltip {
  position: absolute;
  z-index: 1;
  display: block;
  visibility: visible;
  line-height: 1.5;
  opacity: 0.9;

  /*Kana styles*/
  padding-top: var(--spacing-s);
  padding-bottom: 0;
  font-size: var(--font-size-body3);
}

.TooltipSlider .rc-tooltip-arrow {
  display: none;
}

.TooltipSlider .rc-tooltip-inner {
  text-align: left;
  text-decoration: none;
  border-radius: 6px;

  /*Kana styles*/
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  color: var(--color-grey-40);
}
