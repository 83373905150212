.Wizard {
  width: 320px;
  display: flex;
  flex: 0 0 320px;
  flex-direction: column;
  justify-content: flex-start;
  height: min-content;
  position: absolute;
  top: 0;
}

.Wizard .WizardLogo {
  width: 60px;
  margin: calc(var(--spacing-ll) + var(--spacing-xs)) var(--spacing-l);
}

.Wizard .HorizontalStatusContainer {
  padding: var(--spacing-ml) var(--spacing-2xl) var(--spacing-ml) var(--spacing-l);
}

.Wizard .Steps {
  margin: var(--spacing-l) var(--spacing-l);
}

.Wizard .Button {
  margin: calc(5 * var(--spacing-ll)) calc(2 * var(--spacing-3xl)) var(--spacing-ll) var(--spacing-l);
}

