.DocumentCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  background-color: var(--color-light-grey);
  padding: var(--spacing-l);
  border: none;
  border-radius: 8px;
}

.DocumentCard:hover > .DocumentCardTitle {
  color: var(--color-blue-60);
}

.DocumentCard:hover > .DocumentCardTitle > svg > path {
  stroke: var(--color-blue-60);
}

.DocumentCard:hover > .DocumentCardUploadDateText {
  color: var(--color-grey-20);
}

.DocumentCard > .DocumentCardTitle {
  display: flex;
  align-items: center;
  color: var(--color-blue);
  gap: var(--spacing-ml);
}

.DocumentCard > .DocumentCardUploadDateText {
  color: var(--color-grey-40);
}
