.InformationBox {
  padding: var(--spacing-ml) var(--spacing-ml);
}

.InformationBoxSuccess {
  background-color: var(--color-green-20);
}

.InformationBoxAlert {
  background-color: var(--color-yellow-20);
}

.InformationBoxInfo {
  background-color: var(--color-grey-20);
}

.InformationBox .InformationBoxHeaderArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-m);
  margin-bottom: var(--spacing-sl);
}

.InformationBox .InformationBoxNoChildren {
  margin-bottom: 0;
}

.InformationBox .InformationBoxHeaderArea svg {
  width: 20px;
  height: 20px;
}

.InformationBox .InformationBoxHeaderArea > h3 {
  margin-bottom: var(--spacing-xs);
}

.InformationBox .InformationBoxTextArea {
  margin-left: calc(var(--spacing-ml) + 20px + var(--spacing-m) - var(--spacing-ml));
  text-align: left;
}

.InformationBox .InformationBoxTextArea > ul {
  padding-left: 0;
  margin-top: 0;
  margin-left: calc(var(--spacing-2xl) - calc(var(--spacing-ml) + 20px + var(--spacing-m) - var(--spacing-ml)));
}
