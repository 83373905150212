.Dashboard {
  display: flex;
  flex-direction: column;
}
.Dashboard__DashboardColumn {
  flex-direction: column;
  flex-grow: 1;
  margin-right: 10px;
}

.Dashboard__DashboardColumn .last {
  margin: 0;
}

.Dashboard__DashboardRow {
  flex-direction: row;
  display: flex;
}
