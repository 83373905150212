.ChartLegend {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ChartLegendBox {
  width: 16px;
  height: 16px;
  border-radius: 2px;
}

.ChartLegendNoValue {
  font-weight: bold;
  padding: var(--spacing-s) var(--spacing-s);
}

.ChartLegendValue {
  font-weight: bold;
  padding: var(--spacing-s) var(--spacing-m);
}
