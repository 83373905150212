.Modal {
  position: fixed;
  min-width: 760px;
  width: 66.66666667%;
  max-width: 960px;
  max-height: 80vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 200;
  padding: var(--spacing-ml) var(--spacing-ml) var(--spacing-2xl) var(--spacing-ml);
  box-sizing: border-box;
  transition: all 1s ease-out;

  background-color: var(--color-white);
  box-shadow: 0 0 var(--box-shadow-spread) var(--color-box-shadow);
}

.ModalContent {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.Modal h1 {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-ml);
}

.ModalCloseIcon {
  border: none;
  background: none;
  padding: 0;

  align-self: flex-end;
}

.ModalCloseIcon:hover {
  cursor: pointer;
}

.ModalCloseIcon:hover svg path {
  fill: var(--color-grey-60);
}
