.DataGrid .rdg {
  box-sizing: inherit;
  --rdg-header-background-color: #fff;
  --rdg-row-hover-background-color: #fff;
  --rdg-font-size: var(--font-size-body2);
  --rdg-header-row-height: var(--spacing-xl) !important;
  --rdg-border-color: var(--color-grey-20);
  border: 0;
  block-size: inherit;
  /* grid-template-rows: var(--spacing-xl) repeat(5, var(--spacing-xl)) !important; */
}

.DataGrid .rdg .rdg-cell {
  border-inline-end: 0;
  padding: 0 var(--spacing-m);
}

.DataGrid .rdg .rdg-row {
  --rdg-row-height: var(--spacing-xl) !important;
}

.DataGrid .rdg .rdg-cell[aria-selected="true"] {
  outline: 0;
}

.DataGrid .rdg .rdg-cell .left {
  text-align: left;
}

.DataGrid .rdg .rdg-cell .center {
  text-align: center;
}

.DataGrid .rdg .rdg-cell .right {
  text-align: right;
}

.DataGridHeaderColumnName {
  margin: 0 -16px; /* can't set negative margins with var() */
  padding: 0 var(--spacing-m);
  border-bottom: 1px solid var(--color-grey-20);
  height: var(--spacing-xl); /* same as row height */
  line-height: var(--spacing-xl); /* same as row height */
  display: flex;
  align-items: center;
}

.DataGridHeaderColumnName.left {
  justify-content: left;
}

.DataGridHeaderColumnName.center {
  justify-content: center;
}

.DataGridHeaderColumnName.right {
  justify-content: right;
}

.DataGridHeaderFilter {
  line-height: var(--spacing-ml);
  border: 1px solid var(--color-grey-20);
  padding: 0 var(--spacing-s);
  width: 100%;
}

.DataGridHeaderColumnName_sortable {
  cursor: pointer;
}

.DataGridHeaderSortIcon {
  margin-right: var(--spacing-s);
}

.DataGridHeaderSortIcon_asc {
  transform: rotate(90deg);
}

.DataGridHeaderSortIcon_desc {
  transform: rotate(-90deg);
}

.DataGrid .rdg .rdg-cell .Pill {
  /* 48px row height - 24px pill height -> 12px top & bottom margin to fill the row */
  margin-top: var(--spacing-sl);
  width: 100%;
}

.DataGridContainer .NoData {
  color: var(--color-grey-60);
}

.DataGrid .DataGridLink a {
  text-decoration: underline;
  color: var(--color-blue);
}

.DataGridButton .Button {
  padding: 0;
  min-height: auto;
}

.DataGridButton.left .Button {
  text-align: left;
}

.DataGridButton.center .Button {
  text-align: center;
}

.DataGridButton.right .Button {
  text-align: right;
}

.DataGrid .DataGridSelect .Select {
  margin-top: calc((var(--rdg-row-height) - 30px) / 2);
}

.DataGrid .DataGridSelect .Select-selector {
  height: 30px !important;
}

.DataGridSelect.left .Select-selector {
  text-align: left;
}

.DataGridSelect.center .Select-selector {
  text-align: center;
}

.DataGridSelect.right .Select-selector {
  text-align: right;
}

.DataGrid .DataGridSelect .Select-selection-item,
.DataGrid .DataGridSelect .Select-selection-placeholder {
  line-height: var(--line-height-h2) !important;
}

.DataGrid .DataGridInput input {
  height: 30px;
  line-height: var(--line-height-h2);
  margin-top: calc((var(--rdg-row-height) - var(--line-height-h2)) / 2);
}

.DataGrid .DataGridInput.left input {
  text-align: left;
}

.DataGrid .DataGridInput.center input {
  text-align: center;
}

.DataGrid .DataGridInput.right input {
  text-align: right;
}

.DataGridResultCount {
  color: var(--color-blue-40);
  margin-left: auto;
  margin-right: auto;
}
