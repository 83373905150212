.AboutDeveloperContainer h3 {
  margin: 0;
}

.AboutDeveloperProjectDescription,
.AboutDeveloperOtherProjectDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.AboutDeveloperProjectDescription {
  -webkit-line-clamp: 6;
  line-clamp: 6;
}

.AboutDeveloperOtherProjectDescription {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.AboutDeveloperProjects {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
}
