.StatusCard {
  height: 100%;
  padding: var(--spacing-ml);
}

.StatusCard_color_green {
  background-color: var(--color-green);
}

.StatusCard_color_coral {
  background-color: var(--color-coral);
}

.StatusCard_color_grey {
  background-color: var(--color-grey-40);
}

.StatusCard > h3 {
  margin-bottom: var(--spacing-ml);
  color: var(--color-white);
}

.StatusCard > h2 {
  color: var(--color-white);
}
